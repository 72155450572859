import React, { useEffect, useState } from "react";
import { deleteShipment, getShipmentList } from "../../api/imports";
import {
  Box,
  Button,
  Chip,
  FormControlLabel,
  FormGroup,
  Stack,
  ThemeProvider,
  Typography,
} from "@mui/material";
import LGModalCrud from "../LGCrud/LGModalCrud";
import ShipmentForm from "./ShipmentForm";
import dayjs from "dayjs";
import ShipmentFiles from "./ShipmentFilesTable";
import UpdateFileForm from "./ShipmentFilesTable";
import { lightBlue, orange, yellow } from "@mui/material/colors";
import { LGtheme } from "../../theme/theme";
import { CheckBox } from "@mui/icons-material";

export default function ShipmentTable({ companyId }) {
  const [showCompleted, setShowCompleted] = useState(false);

  const setStatusColor = (statusCode) => {
    switch (statusCode) {
      case "EMBARCADO":
        return "default";
      case "EN_PUERTO":
        return "warning";
      case "EN_TRANSITO_LOCAL":
        return "info";
      case "ENTREGADO":
        return "success";
    }
  };

  const handleChange = () => {
    setShowCompleted(!showCompleted);
  };

  return (
    <Box width={"100%"}>
      <Button onClick={handleChange}>
        {showCompleted ? "Ocultar completados" : "Mostrar completados"}
      </Button>
      <LGModalCrud
        props={{
          actions: ["create", "update", "delete", "detail"],
          data: { companyId, showCompleted },
          fetchFunction: getShipmentList,
          deleteFunction: deleteShipment,
          detailMenu: [
            {
              label: "Documentos",
              handler: () => {},
              header: "Documentos",
              body: <UpdateFileForm />,
            },
          ],
          columns: [
            {
              field: "ref",
              headerName: "Ref",
              flex: 0.2,
            },
            {
              field: "provider",
              headerName: "Proveedor",
              flex: 0.2,
              valueGetter: (value, row) => {
                return row.provider ? `${row.provider.code}` : "";
              },
            },
            {
              field: "portArrivalEta",
              headerName: "Fecha de llegada estimada",
              flex: 0.2,
            },
            {
              field: "remainingDays",
              headerName: "Dias restantes",
              flex: 0.2,
              renderCell: (params) => {
                const remainingDays =
                  -1 * dayjs().diff(params.row.portArrivalEta, "d");
                return (
                  <Box
                    mt={2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Chip
                      size="small"
                      label={remainingDays}
                      color={
                        remainingDays > 5
                          ? "success"
                          : remainingDays > 0
                          ? "warning"
                          : "error"
                      }
                    />
                  </Box>
                );
              },
            },

            {
              field: "containers",
              headerName: "Contenedores",
              flex: 0.2,
              renderCell: (params) => (
                <Stack direction={"column"}>
                  {params.value.map((e) => (
                    <Typography variant="body2" key={e.id}>
                      {e.code} | {e.description}
                    </Typography>
                  ))}
                </Stack>
              ),
            },
            {
              field: "status",
              headerName: "Estatus",
              flex: 0.2,
              renderCell: (params) => (
                <Box
                  mt={2}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Chip
                    size="small"
                    label={params.row.status.name}
                    color={setStatusColor(params.row.status.code)}
                  />
                </Box>
              ),
            },
          ],
          addModalData: {
            header: "Agregar Embarque",
            body: <ShipmentForm />,
          },
          editModalData: {
            header: "Editar Embarque",
            body: <ShipmentForm />,
          },
        }}
      />
    </Box>
  );
}
