import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import { Box, Grid, Stack, Tab, Tabs, Typography } from "@mui/material";
import { LGTabPanel } from "../../molecules/LGTabPanel";
import CrudImportForeignTransport from "../../organisms/Catalogues/CrudImportForeignTransport";
import CrudImportForwarder from "../../organisms/Catalogues/CrudImportForwarder";
import CrudImportCustomsAgent from "../../organisms/Catalogues/CrudImportCustomsAgent";
import CrudImportShipmentType from "../../organisms/Catalogues/CrudImportShipmentType";
import CrudImportShipmentMode from "../../organisms/Catalogues/CrudImportShipmentMode";
import ShipmentTable from "../../organisms/Imports/ShipmentTable";
import ShipmentCalendar from "../../organisms/Imports/ShipmentCalendar";
import { getCompanyList } from "../../api/enterprise";
import { useSnack } from "../../services/SnakbarProvider";
import { handleError } from "../../services/errorHandler";

export default function ShipmentScreen() {
  const [value, setValue] = useState(1);
  const [companies, setCompanies] = useState([]);
  const { openSnack } = useSnack();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchData = async () => {
    try {
      const res = await getCompanyList();
      setCompanies(res.data);
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <DashboardLayout>
      <Box
        sx={{
          backgroundColor: "white",
          borderBottom: 1,
          borderColor: "divider",
          position: "fixed",
          zIndex: 99,
          width: "100%",
        }}
      >
        <Tabs value={value} onChange={handleChange} sx={{}}>
          {companies
            .filter((e) => e.id < 3)
            .map((e) => (
              <Tab label={`Embarques ${e.name}`} value={e.id} key={e.id} />
            ))}
          <Tab label="Catálogos" value={3} key={3} />
          <Tab />
        </Tabs>
      </Box>

      <LGTabPanel value={value} index={1}>
        <Grid container direction={"row-reverse"} spacing={2}>
          <Grid item md={12} xl={6} height={"600px"}>
            <ShipmentCalendar companyId={value} />
          </Grid>
          <Grid item md={12} xl={6}>
            <ShipmentTable companyId={value} />
          </Grid>
        </Grid>
      </LGTabPanel>

      <LGTabPanel value={value} index={2}>
        <Grid container direction={"row-reverse"} spacing={2}>
          <Grid item md={12} xl={6} height={"600px"}>
            <ShipmentCalendar companyId={value} />
          </Grid>
          <Grid item md={12} xl={6}>
            <ShipmentTable companyId={value} />
          </Grid>
        </Grid>
      </LGTabPanel>

      <LGTabPanel value={value} index={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Stack direction={"column"} spacing={2} mt={5}>
              <Typography variant="h4">Navieras / Aerolineas</Typography>
              <CrudImportForeignTransport />
              <Typography variant="h4">Forwarders</Typography>
              <CrudImportForwarder />
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction={"column"} spacing={2} mt={5}>
              <Typography variant="h4">Agentes Aduanales</Typography>
              <CrudImportCustomsAgent />
              <Typography variant="h4">Tipos de Embarque</Typography>
              <CrudImportShipmentType />
              <Typography variant="h4">Modos de Embarque</Typography>
              <CrudImportShipmentMode />
            </Stack>
          </Grid>
        </Grid>
      </LGTabPanel>
    </DashboardLayout>
  );
}
