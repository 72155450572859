import React from "react";
import {
  deleteProviderAccounts,
  getProviderAccountsList,
  getProviderList,
} from "../../../api/providers";
import { Box, Stack, Typography } from "@mui/material";
import LGModalCrud from "../../LGCrud/LGModalCrud";
import UpdateProviderFromAccountForm from "../../../molecules/Providers/UpdateProviderFromAccount";
import ProviderContactList from "../ProviderContactList";
import BankAccountList from "../BankAccountList";
import ActivityTable from "../ActivityTable";
import CrudProviderContract from "../ContractTable";
import ProviderForm from "../ProviderForm";

export default function CrudProviderAccounts({ props }) {
  const { companyId } = props;

  return (
    <>
      <Box width={"100%"}>
        <LGModalCrud
          props={{
            actions: ["create", "update", "delete", "detail"],
            data: { companyId },
            fetchFunction: getProviderList,
            deleteFunction: deleteProviderAccounts,
            detailMenu: [
              {
                label: "Dirección y Contacto",
                handler: () => {},
                header: "Contactos del proveedor",
                body: <ProviderContactList />,
              },
              {
                label: "Info. Bancaria",
                handler: () => {},
                header: "Información bancaria",
                body: <BankAccountList />,
              },
              {
                label: "Actividades",
                handler: () => {},
                header: "Actividades del proveedor",
                body: <ActivityTable />,
              },
              {
                label: "Documentos",
                handler: () => {},
                header: "Lista de Documentos del proveedor",
                body: <CrudProviderContract />,
              },
            ],
            columns: [
              {
                field: "code",
                headerName: "Clave",
                flex: 0.2,
              },
              {
                field: "legalName",
                headerName: "Nombre o razón social",
                flex: 0.3,
              },
              {
                field: "taxId",
                headerName: "RFC / Tax ID",
                flex: 0.2,
              },
              {
                field: "accounts",
                headerName: "Cuentas contables",
                flex: 0.5,
                renderCell: (params) => (
                  <Stack direction={"column"}>
                    {params.value.map((e) => (
                      <Typography variant="body2" key={e.id}>
                        {e.contpaqiAccountNo} - {e.description}
                      </Typography>
                    ))}
                  </Stack>
                ),
              },
            ],
            addModalData: {
              header: "Agregar Proveedor",
              body: <ProviderForm companyId={companyId} />,
            },
            editModalData: {
              header: "Editar Proveedor",
              body: <ProviderForm companyId={companyId} />,
            },
          }}
        />
      </Box>
    </>
  );
}
