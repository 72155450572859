import { api } from "..";

const endpoint = "/api/products";

// PRODUCTS
export const getProductList = async (params) => {
  const { code, ExpenseAccountIds } = params;

  const queryParams = new URLSearchParams();

  if (code) queryParams.append("code", code);

  // Manually add each ExpenseAccountId without the brackets
  if (ExpenseAccountIds && ExpenseAccountIds.length > 0) {
    ExpenseAccountIds.forEach((id) =>
      queryParams.append("ExpenseAccountIds", id)
    );
  }

  const response = await api.get(`/api/products?${queryParams.toString()}`);
  return response;
};

export const getProductById = (id) => {
  return api.get(`${endpoint}/${id}`);
};

export const addProduct = (data) => {
  return api.post(`${endpoint}`, data);
};

export const updateProduct = (id, data) => {
  return api.put(`${endpoint}/${id}`, data);
};

export const deleteProduct = (id) => {
  return api.delete(`${endpoint}/${id}`);
};
