import React, { useEffect, useState } from "react";
import { useField } from "formik";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import { useSnack } from "../../services/SnakbarProvider";
import { handleError } from "../../services/errorHandler";

const DropdownSelector = ({
  label,
  optionLabel,
  parentOptions,
  fetchFunction,
  ...props
}) => {
  const [field, meta] = useField(props.name);
  const [options, setOptions] = useState([]);
  const { openSnack } = useSnack();

  const fetchData = async () => {
    try {
      const res = await fetchFunction();
      setOptions(res.data);
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  useEffect(() => {
    if (parentOptions) {
      setOptions(options);
    } else {
      fetchData();
    }
  }, [fetchFunction]);

  return (
    <FormControl
      fullWidth
      variant="outlined"
      error={meta.touched && Boolean(meta.error)}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        {...field}
        {...props}
        value={field.value || ""}
        onChange={field.onChange}
        onBlur={field.onBlur}
      >
        {options &&
          Array.isArray(options) &&
          options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {optionLabel ? option[optionLabel] : option.name}
            </MenuItem>
          ))}
      </Select>
      {meta.touched && meta.error ? (
        <FormHelperText>{meta.error}</FormHelperText>
      ) : null}
    </FormControl>
  );
};

export default DropdownSelector;
