import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  TextField,
  MenuItem,
  Autocomplete,
  Select,
} from "@mui/material";
import { FastField, Field, useFormikContext } from "formik";
import DeleteIcon from "@mui/icons-material/Delete";
import DropdownSelector from "../../atoms/Common/DropdownSelector";
import {
  getCatalogue,
  getCatalogueEntryById,
} from "../../api/common/catalogues";
import { getProductList } from "../../api/products";
import { useSnack } from "../../services/SnakbarProvider";
import { handleError } from "../../services/errorHandler";

const POItemRow = ({ index, remove, activityIds }) => {
  const { setFieldValue, values, touched, errors } = useFormikContext();
  const [productOptions, setProductOptions] = useState([]);
  const [assetCode, setAssetCode] = useState(0);
  const [assetOptions, setAssetOptions] = useState([
    {
      id: 238,
      name: "KILOGRAMO",
      code: "Kg",
      catalogueName: "UNIT",
      props: null,
    },
    {
      id: 239,
      name: "MILIMETRO",
      code: "mm",
      catalogueName: "UNIT",
      props: null,
    },
    {
      id: 240,
      name: "GRAMO",
      code: "gr",
      catalogueName: "UNIT",
      props: null,
    },
    {
      id: 241,
      name: "CENTIMETRO",
      code: "cm",
      catalogueName: "UNIT",
      props: null,
    },
    {
      id: 242,
      name: "PUNTO DE PULGADA",
      code: "pt",
      catalogueName: "UNIT",
      props: null,
    },
    {
      id: 243,
      name: "LITRO",
      code: "lt",
      catalogueName: "UNIT",
      props: null,
    },
    {
      id: 244,
      name: "METRO",
      code: "m",
      catalogueName: "UNIT",
      props: null,
    },
  ]);
  const { openSnack } = useSnack();

  const fetchProductOptions = async (inputValue) => {
    try {
      const res = await getProductList({
        code: inputValue ? inputValue.toUpperCase() : null,
        ExpenseAccountIds: activityIds,
      });
      setProductOptions(res.data);
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  const fetchAssetOptions = async () => {
    try {
      const res = await getCatalogue();
      setAssetOptions(res.data);
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  // Fetch asset options when assetTypeId changes
  useEffect(() => {
    const assetTypeId = values.items?.[index]?.assetTypeId;
    if (assetTypeId) {
      getCatalogueEntryById(assetTypeId).then((res) => {
        setAssetCode(res.data.code);
        // getCatalogue(res.data.code)
        //   .then((res) => {
        //     setAssetCode(res.data);
        //     console.log(res.data);
        //   })
        //   .catch((e) => openSnack(handleError(e), "error"));
      });
    }
  }, [values.items?.[index]?.assetTypeId]); // Dependency on assetTypeId

  const handleDelete = () => {
    remove(index);
  };

  useEffect(() => {
    fetchProductOptions();
    fetchAssetOptions();
  }, []);

  useEffect(() => {
    setAssetOptions(assetOptions.filter((e) => e.code == assetCode));
    console.log(assetCode);
  }, [assetCode]);

  return (
    <Grid container spacing={0}>
      <Grid item xs={2}>
        <Autocomplete
          size="small"
          margin="none"
          options={productOptions}
          getOptionLabel={(option) => option.code || ""}
          value={
            productOptions.find(
              (option) => option.id === values.items?.[index]?.productId
            ) || null
          }
          onInputChange={(event, value) => fetchProductOptions(value)}
          onChange={(event, value) => {
            setFieldValue(`items.${index}.productId`, value ? value.id : "");
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={
                touched.items?.[index]?.productId &&
                Boolean(errors.items?.[index]?.productId)
              }
              helperText={
                touched.items?.[index]?.productId &&
                errors.items?.[index]?.productId
              }
            />
          )}
        />
      </Grid>
      <Grid item xs={1}>
        <FastField
          as={TextField}
          name={`items.${index}.quantity`}
          fullWidth
          size="small"
          margin="none"
        />
      </Grid>
      <Grid item xs={1}>
        <FastField
          as={DropdownSelector}
          fetchFunction={() => getCatalogue("UNIT")}
          name={`items.${index}.unitId`}
          fullWidth
          size="small"
          margin="none"
        ></FastField>
      </Grid>
      <Grid item xs={2}>
        <FastField
          as={TextField}
          name={`items.${index}.concept`}
          fullWidth
          multiline
          size="small"
          margin="none"
        />
      </Grid>
      <Grid item xs={1}>
        <FastField
          as={DropdownSelector}
          fetchFunction={() => getCatalogue("ASSET_TYPE")}
          name={`items.${index}.assetTypeId`}
          fullWidth
          size="small"
          margin="none"
        ></FastField>
      </Grid>
      <Grid item xs={1}>
        <Field
          as={DropdownSelector}
          fetchFunction={() => getCatalogue(assetCode)}
          name={`items.${index}.assetId`}
          fullWidth
          size="small"
          margin="none"
        ></Field>
      </Grid>
      <Grid item xs={1}>
        <FastField
          as={TextField}
          name={`items.${index}.price`}
          fullWidth
          size="small"
          margin="none"
        />
      </Grid>
      <Grid item xs={1}>
        <FastField
          as={TextField}
          name={`items.${index}.workOrderId`}
          fullWidth
          size="small"
          margin="none"
        />
      </Grid>
      <Grid item xs={1}>
        <IconButton color="error" onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default React.memo(POItemRow);
