import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { getProviderList } from "../../api/providers";
import ProviderTable from "../../organisms/Providers/ProviderTable";
import { LGTabPanel } from "../../molecules/LGTabPanel";
import { getCompanyList } from "../../api/enterprise";
import CrudProviderAccounts from "../../organisms/Providers/AccountList/CrudProviderAccounts";
import LGModalCrud from "../../organisms/LGCrud/LGModalCrud";
import { useSnack } from "../../services/SnakbarProvider";
import { handleError } from "../../services/errorHandler";
import { getUserCompany } from "../../services/auth";

export default function ProviderScreen() {
  const [value, setValue] = useState(1);
  const [companies, setCompanies] = useState([]);
  const { openSnack } = useSnack();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchData = async () => {
    try {
      const res = await getCompanyList();
      let companyAllowed = await getUserCompany();

      setCompanies(res.data?.filter((e) => e.id == companyAllowed));
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <DashboardLayout>
      <Box p={4}>
        <Typography
          variant="h4"
          component="h1"
          sx={{ textTransform: "uppercase" }}
        >
          Catálogo de proveedores
        </Typography>
        <Box>
          <Tabs value={value} onChange={handleChange}>
            {companies.map((e) => (
              <Tab label={e.name} value={e.id} key={e.id} />
            ))}
          </Tabs>
        </Box>
        <LGTabPanel value={value} index={1}>
          <CrudProviderAccounts props={{ companyId: value }} />
        </LGTabPanel>
        <LGTabPanel value={value} index={2}>
          <CrudProviderAccounts props={{ companyId: value }} />
        </LGTabPanel>
        <LGTabPanel value={value} index={3}>
          <CrudProviderAccounts props={{ companyId: value }} />
        </LGTabPanel>
      </Box>
    </DashboardLayout>
  );
}
