import { AppBar, Divider, IconButton, Stack, Toolbar } from "@mui/material";
import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { getUserRoles, isTokenExpired } from "../../services/auth";
import { logout } from "../../api/auth";
import { useNavigate } from "react-router-dom";

const ENV = process.env.REACT_APP_ENV;
const drawerWidth = 240;

export default function LGAppBar({ open, handleopenDrawer }) {
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const userData = getUserRoles();
  const navigate = useNavigate();

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (isTokenExpired()) {
      handleLogout();
    }
  }, []);

  return (
    <AppBar
      position="fixed"
      sx={{
        height: "64px",
        backgroundColor:
          ENV == "LOCAL" ? "#582A72" : ENV == "QA" ? "#297B48" : "#1A73E8",
        transition:
          "margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
        ...(open && {
          width: `calc(100% - ${drawerWidth}px)`,
          marginLeft: `${drawerWidth}px`,
          transition:
            "margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
        }),
      }}
    >
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleopenDrawer}
          sx={{ mr: 2, ...(open && { display: "none" }) }}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          variant="h6"
          component="div"
          sx={{ flexGrow: 1 }}
        ></Typography>
        {auth && (
          <div>
            <Stack direction="row" spacing={2} alignItems="center">
              {userData ? (
                <Typography>Bienvenido {userData.user}</Typography>
              ) : (
                <Typography>Sin sesión</Typography>
              )}
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </Stack>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {userData.roles && [
                <MenuItem key={"roles-title"}>Roles:</MenuItem>,
                <Divider key={"roles-divider"} />,
              ]}
              {userData.roles &&
                userData.roles.map((e) => <MenuItem key={e}>{e}</MenuItem>)}
              <Divider />
              <MenuItem onClick={handleLogout}>
                <Typography sx={{ color: "error.main" }}>
                  Cerrar Sesión
                </Typography>
              </MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}
