import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  Icon,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import { getCatalogue } from "../../api/common/catalogues";
import { getExpenseAccountList } from "../../api/accounting";
import { useSnack } from "../../services/SnakbarProvider";
import DropdownSelector from "../../atoms/Common/DropdownSelector";
import DeleteIcon from "@mui/icons-material/Delete";
import { addProduct, getProductById, updateProduct } from "../../api/products";
import { handleError } from "../../services/errorHandler";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("El campo nombre es obligatorio"),
  originalCode: Yup.string().required(
    "El campo código original es obligatorio"
  ),
  key: Yup.string().required("El campo clave es obligatorio"),
  code: Yup.string().required("El campo código es obligatorio"),
  brand: Yup.string().required("El campo marca es obligatorio"),
  productTypeId: Yup.number().required(
    "El campo tipo de producto es obligatorio"
  ),
  unitId: Yup.number().required("El campo unidad es obligatorio"),
  description: Yup.string().required("El campo descripción es obligatorio"),
  expenseAccountId: Yup.number().required(
    "El campo cuenta de gastos es obligatorio"
  ),
  props: Yup.array().of(
    Yup.object().shape({
      key: Yup.string().required("La clave es obligatoria"),
      value: Yup.string().required("El valor es obligatorio"),
    })
  ),
});

const initialValues = {
  name: "",
  originalCode: "",
  key: "",
  code: "",
  brand: "",
  productTypeId: "",
  props: [
    { key: "", value: "" },
    { key: "", value: "" },
    { key: "", value: "" },
    { key: "", value: "" },
    { key: "", value: "" },
  ],
  unitId: "",
  description: "",
  expenseAccountId: "",
};

const ProductForm = ({ id, onSubmit, companyId }) => {
  const formMode = id ? "edit" : "insert";
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(initialValues);
  const { openSnack } = useSnack();

  const handleSubmit = async (values) => {
    try {
      if (formMode == "insert") {
        let payload = { ...values };
        payload.props = payload.props.reduce((acc, curr) => {
          acc[curr.key] = curr.value;
          return acc;
        }, {});
        await addProduct(payload);
        openSnack("Producto guardado satisfactoriamente", "success");
        onSubmit();
      } else if (formMode == "edit") {
        let payload = { ...values };
        payload.props = payload.props.reduce((acc, curr) => {
          acc[curr.key] = curr.value;
          return acc;
        }, {});
        await updateProduct(id, payload);
        openSnack("Producto actualizado satisfactoriamente", "success");
        onSubmit();
      }
    } catch (e) {
      openSnack(e, "error");
    }

    console.log(values);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await getProductById(id);
      const props = Object.entries(res.data.props).map(([key, value]) => ({
        key,
        value,
      }));
      const payload = { ...res.data, props };
      setFormData(payload);
    } catch (e) {
      openSnack(handleError(e), "error");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (formMode == "edit") {
      fetchData();
    } else {
      setFormData(initialValues);
    }
  }, []);

  return loading ? (
    <CircularProgress />
  ) : (
    <Formik
      initialValues={{ ...formData }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Field
                fullWidth
                name="name"
                type="text"
                label="Nombre"
                as={TextField}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                fullWidth
                name="originalCode"
                type="text"
                label="Código Original"
                as={TextField}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                fullWidth
                name="key"
                type="text"
                label="Clave"
                as={TextField}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                fullWidth
                name="code"
                type="text"
                label="Código"
                as={TextField}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                fullWidth
                name="brand"
                type="text"
                label="Marca"
                as={TextField}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                fullWidth
                name="productTypeId"
                as={DropdownSelector}
                fetchFunction={() => getCatalogue("PRODUCT_TYPE")}
                label="Tipo de Producto"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                fullWidth
                name="unitId"
                as={DropdownSelector}
                fetchFunction={() => getCatalogue("UNIT")}
                label="Unidad"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                fullWidth
                name="expenseAccountId"
                as={DropdownSelector}
                fetchFunction={getExpenseAccountList}
                label="Cuenta de Gastos"
              />
            </Grid>

            <Grid item xs={12}>
              <Field
                fullWidth
                name="description"
                type="text"
                label="Descripción"
                as={TextField}
                multiline
                rows={4}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4">Propiedades del producto</Typography>
            </Grid>
            <Grid item xs={12}>
              <FieldArray name="props">
                {({ push, remove }) => (
                  <Grid container item xs={12} spacing={2}>
                    {values.props.map((prop, index) => (
                      <>
                        <Grid item xs={6}>
                          <Field
                            fullWidth
                            name={`props.${index}.key`}
                            type="text"
                            label="Clave"
                            as={TextField}
                          />
                        </Grid>
                        <Grid item xs={5}>
                          <Field
                            fullWidth
                            name={`props.${index}.value`}
                            type="text"
                            label="Valor"
                            as={TextField}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            onClick={() => remove(index)}
                            color="error"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </>
                    ))}
                    <Button
                      fullWidth
                      type="button"
                      onClick={() => push({ key: "", value: "" })}
                      variant="contained"
                      sx={{ mt: 2, mx: 2 }}
                    >
                      <Icon>add</Icon>
                    </Button>
                  </Grid>
                )}
              </FieldArray>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" type="submit">
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default ProductForm;
