import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import PurchaseOrderDetails from "../../molecules/PurchaseOrder/PurchaseOrderDetail";
import { getProviderList } from "../../api/providers";
import { useSnack } from "../../services/SnakbarProvider";
import { handleError } from "../../services/errorHandler";
import DropdownSelector from "../../atoms/Common/DropdownSelector";
import { getPlantList } from "../../api/enterprise";
import dayjs from "dayjs";
import MyDatePicker from "../../atoms/Common/MyDatePicker";

const validationSchema = Yup.object({
  providerId: Yup.string().required("Requerido"),
  plantId: Yup.number().required("Requerido").positive().integer(),
  companyId: Yup.number().required("Requerido").positive().integer(),
  statusId: Yup.number().required("Requerido").positive().integer(),
  code: Yup.string().required("Requerido"),
  arrivalEta: Yup.string().required("Requerido"),
  requestedFrom: Yup.string().required("Requerido"),
  approvedBy: Yup.string().required("Requerido"),
  discountPercentage: Yup.number().required("Requerido").min(0).max(100),
  notes: Yup.string().required("Requerido"),
  observations: Yup.string().required("Requerido"),
  details: Yup.array()
    .of(
      Yup.object({
        quantity: Yup.number().required("Requerido").positive().integer(),
        unitPrice: Yup.number().required("Requerido").positive(),
        productId: Yup.number().required("Requerido").positive().integer(),
        product: Yup.object(),
        // activityId: Yup.number().required("Requerido").positive().integer(),
        activityId: Yup.object(),
      })
    )
    .required("Se requiere al menos un detalle"),
});

const initialValues = {
  providerId: "",
  plantId: "",
  companyId: "",
  statusId: "",
  code: "",
  arrivalEta: "",
  requestedFrom: "",
  approvedBy: "",
  discountPercentage: 0,
  notes: "",
  observations: "",
  details: [{ quantity: 0, unitPrice: 0, productId: "", activityId: "" }],
};

const PurchaseOrderForm = (onSubmit, id) => {
  const formMode = id ? "edit" : "insert";
  const [providerOptions, setProviderOptions] = useState([]);
  const { openSnack } = useSnack();

  const fetchProviderOptions = async (inputValue) => {
    try {
      const res = await getProviderList({ code: inputValue.toUpperCase() });
      setProviderOptions(res.data);
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        console.log(values);
        // handle form submission
      }}
    >
      {({ isSubmitting, setFieldValue, touched, errors, values }) => (
        <Form>
          <Grid container spacing={2} alignItems={"flex-start"}>
            {/* Información General */}
            <Grid
              container
              item
              xs={3}
              pr={2}
              spacing={2}
              alignItems={"flex-start"}
            >
              <Grid item xs={12}>
                <Typography variant="h5">Datos Generales</Typography>
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="code"
                  label="Clave Órden de compra"
                  as={TextField}
                  sx={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  id="providerId"
                  name="providerId"
                  options={providerOptions}
                  getOptionLabel={(option) => option.code}
                  onInputChange={(event, value) => fetchProviderOptions(value)}
                  onChange={(event, value) => {
                    setFieldValue(`providerId`, value ? value.id : "");
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Proveedor"
                      error={touched.providerId && Boolean(errors.providerId)}
                      helperText={touched.providerId && errors.providerId}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="plantId"
                  label="Planta Destino"
                  as={DropdownSelector}
                  fetchFunction={getPlantList}
                />
              </Grid>

              <Grid item xs={12}>
                <MyDatePicker
                  fullWidth
                  slotProps={{ textField: { fullWidth: true } }}
                  label="Fecha Estimada de llegada"
                  value={values.arrivalEta ? dayjs(values.arrivalEta) : null}
                  onChange={(value) => setFieldValue("arrivalEta", value)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  name="requestedFrom"
                  label="Pedido Por"
                  as={TextField}
                  sx={{ width: "100%" }}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  name="approvedBy"
                  label="Autorizado por"
                  as={TextField}
                  sx={{ width: "100%" }}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  name="notes"
                  label="Notas"
                  as={TextField}
                  multiline
                  rows={3}
                  sx={{ width: "100%" }}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  name="observations"
                  label="Observaciones"
                  as={TextField}
                  multiline
                  rows={3}
                  sx={{ width: "100%" }}
                />
              </Grid>
            </Grid>

            <Divider
              orientation="vertical"
              flexItem
              sx={{ mr: "-1px", backgroundColor: "#88c" }}
            />

            {/* Artículos */}
            <Grid container item xs={9} spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5">Artículos</Typography>
              </Grid>
              <Grid item xs={12}>
                <PurchaseOrderDetails />
              </Grid>

              <Grid item xs={12}>
                <Box height={"1px"} sx={{ backgroundColor: "#88c" }}></Box>
              </Grid>
              {/* TOTAL */}
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5">Total</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name="discountPercentage"
                    label="Descuento (%)"
                    as={TextField}
                    sx={{ width: "100%" }}
                  />
                </Grid>
                <Grid container item xs={6} direction={"column"}>
                  <Stack direction={"column"}>
                    <Typography variant="body2">Total descuento</Typography>
                    <Chip label={"$123.12"} />
                  </Stack>
                  <Stack direction={"column"}>
                    <Typography variant="body2">Total impuestos</Typography>
                    <Chip label={"$123.12"} />
                  </Stack>
                  <Stack direction={"column"}>
                    <Typography variant="body1">Total</Typography>
                    <Chip label={"$12312.12"} color={"success"} />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={() => {
                  console.log(values);
                }}
              >
                Enviar Pedido
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default PurchaseOrderForm;
