import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useSnack } from "../../services/SnakbarProvider";
import { getProviderList } from "../../api/providers";
import { handleError } from "../../services/errorHandler";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import DropdownSelector from "../../atoms/Common/DropdownSelector";
import { getCompanyList, getPlantList } from "../../api/enterprise";
import { useNavigate } from "react-router-dom";
import { getCurrencyList } from "../../api/common/currencies";
import CompanyDropdownSelector from "../../atoms/Common/CompanyDropdownSelector";
import {
  addPurchaseOrder,
  getPurchaseOrderDetail,
  updatePurchaseOrder,
} from "../../api/purchaseOrders";
import { getUserCompany } from "../../services/auth";
import MyDatePicker from "../../atoms/Common/MyDatePicker";

export const validationSchema = Yup.object().shape({
  providerId: Yup.string().required("El proveedor es obligatorio"),
  companyId: Yup.number().required("La empresa es obligatoria"),
  plantId: Yup.number().required("La planta es obligatoria"),
  arrivalDate: Yup.date().required("La fecha de llegada es obligatoria"),
  arrivalEta: Yup.date(),
  requestedFrom: Yup.string().required("El usuario solicitante es obligatorio"),
  currencyId: Yup.number().required("La moneda es obligatoria"),
  noConcepts: Yup.number("Debe ser un número")
    .positive()
    .required("El número de conceptos es obligatorio"),
  notes: Yup.string().required("La nota es obligatoria"),
});

const initialValues = {
  providerId: "",
  companyId: getUserCompany() ?? "",
  plantId: 8,
  arrivalDate: dayjs().add(1, "day"),
  arrivalEta: dayjs().add(1, "day"),
  requestedFrom: "",
  currencyId: 1,
  noConcepts: "",
  notes: "FAVOR DE ELABORAR LA FACTURA EN LA FORMA PPD",
};

export default function PurchaseOrderFormV2({
  props,
  onSubmit,
  id,
  duplicate = false,
}) {
  const ID = id || props?.id;
  const formMode = ID ? (duplicate ? "duplicate" : "edit") : "insert";

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(initialValues);
  const [providerOptions, setProviderOptions] = useState([]);
  const { openSnack } = useSnack();
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await getPurchaseOrderDetail(ID);
      const noConcepts = res.data.items.length;
      setFormData({ ...res.data, noConcepts });
    } catch (e) {
      openSnack(handleError(e), "error");
    }
    setLoading(false);
  };

  const fetchProviderOptions = async (inputValue) => {
    try {
      const res = await getProviderList({
        code: inputValue ? inputValue.toUpperCase() : null,
      });
      setProviderOptions(res.data);
    } catch (e) {
      openSnack(handleError(e), "error");
    }
  };

  const generatePayload = (values) => {
    let payload = { ...values };
    delete payload.noConcepts;
    const formattedDate = values.arrivalDate
      ? dayjs(values.arrivalDate).format("YYYY-MM-DD")
      : "";
    const formattedEtaDate = values.arrivalEta
      ? dayjs(values.arrivalEta).format("YYYY-MM-DD")
      : "";
    payload.arrivalDate = formattedDate;
    payload.arrivalEta = formattedEtaDate;
    return payload;
  };

  const handleNavigate = (POdata) => {
    navigate("/purchase-orders/concepts", { state: POdata });
  };

  const handleSubmit = async (values) => {
    let payload = generatePayload(values);
    try {
      let POdata = {};
      if (formMode == "insert") {
        const res = await addPurchaseOrder(payload);
        POdata = res.data;
        POdata.noConcepts = values.noConcepts;
      } else if (formMode == "edit") {
        let updatePayload = { purchaseOrder: payload, items: payload.items };
        const res = await updatePurchaseOrder(id, updatePayload);
        POdata = res.data;
        POdata.items = payload.items;
        handleNavigate(POdata);
      } else if (formMode == "duplicate") {
        delete payload.id;
        const res = await addPurchaseOrder(payload);
        POdata = res.data;
        POdata.items = payload.items;
      }
      handleNavigate(POdata);
    } catch (e) {
      console.log(e);

      openSnack(handleError(e), "error");
    }
  };

  useEffect(() => {
    fetchProviderOptions();
    if (["duplicate", "edit"].includes(formMode)) {
      fetchData();
    } else {
      setFormData(initialValues);
    }
  }, []);

  return loading ? (
    <CircularProgress />
  ) : (
    <Formik
      enableReinitialize
      initialValues={{ ...formData }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        isSubmitting,
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        errors,
        touched,
      }) => (
        <Form>
          <Grid container spacing={2}>
            {formMode == "insert" && (
              <Grid item xs={12}>
                <Typography variant="h4">Nueva orden de compra</Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Autocomplete
                id="providerId"
                name="providerId"
                options={providerOptions}
                getOptionLabel={(option) =>
                  // `${option.code} | ${option.legalName}` || ""
                  option.code || ""
                }
                value={
                  providerOptions.find(
                    (option) => option.id === values.providerId
                  ) || null
                }
                onInputChange={(event, value) => fetchProviderOptions(value)}
                onChange={(event, value) => {
                  setFieldValue("providerId", value ? value.id : "");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Proveedor"
                    error={touched.providerId && Boolean(errors.providerId)}
                    helperText={touched.providerId && errors.providerId}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="companyId"
                label="Empresa"
                as={CompanyDropdownSelector}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="plantId"
                label="Planta destino"
                as={DropdownSelector}
                fetchFunction={getPlantList}
              />
            </Grid>
            <Grid item xs={2}>
              <MyDatePicker
                fullWidth
                slotProps={{ textField: { fullWidth: true } }}
                label="Fecha de entrega"
                value={values.arrivalDate ? dayjs(values.arrivalDate) : null}
                onChange={(value) => setFieldValue("arrivalDate", value)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Grid>
            <Grid item xs={2}>
              <MyDatePicker
                fullWidth
                slotProps={{ textField: { fullWidth: true } }}
                label="Fecha estimada de entrega"
                value={values.arrivalDate ? dayjs(values.arrivalDate) : null}
                onChange={(value) => setFieldValue("arrivalEta", value)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Grid>
            <Grid item xs={2}>
              <Field
                as={TextField}
                name="requestedFrom"
                label="Pedido por"
                fullWidth
                error={touched.requestedFrom && !!errors.requestedFrom}
                helperText={touched.requestedFrom && errors.requestedFrom}
              ></Field>
            </Grid>
            <Grid item xs={2}>
              <Field
                as={DropdownSelector}
                name="currencyId"
                label="Moneda"
                fullWidth
                fetchFunction={getCurrencyList}
                optionLabel="symbol"
              />
            </Grid>
            <Grid item xs={2}>
              <Field
                fullWidth
                name={"noConcepts"}
                label="No. de conceptos"
                as={TextField}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="notes"
                label="Notas"
                as={TextField}
                multiline
                rows={4}
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
