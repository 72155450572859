import { Box, Typography } from "@mui/material";
import React from "react";

export default function POItemCellHeader({ ...props }) {
  return (
    <Box
      bgcolor={props.color ?? "primary.main"}
      width={"100%"}
      height={"100%"}
      p={1}
    >
      <Typography variant="body1" color={"white"}>
        {props.label ?? ""}
      </Typography>
    </Box>
  );
}
